.content {
    margin-left: 30px;
    margin-right: 30px;
    width: 700px;
    margin-bottom: 50px;
}

.headline {
    font-family: FjallaOne, Helvetica, sans-serif;
    font-size: 40px;
    color: #738959;
}

.sub-headline-2 {
    font-size: 30px;
    margin-top: 0px;
    margin-top: 25px;
    margin-bottom: 10px;
    color: #b1c887;
}

.divider {
    border: solid 1px #dae7c3;
}

.role {
    margin-top: 20px;
    margin-bottom: 10px;
}

.exp-year {
    float: right;
}

.title {
    color: #738959;
}

.description {
    margin-top: 10px;
    margin-bottom: 30px;
}

.title:hover {
    color: #b1c887;
}

.role-skills {
    margin-top: 5px;
    margin-bottom: 5px;
}

.college-info {
    margin-top: 10px;
}

.college-subinfo {
    font-size: 13px;
}