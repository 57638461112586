@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(./fonts/Nunito-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(./fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'FjallaOne';
  src: local('FjallaOne'), url(./fonts/FjallaOne-Regular.ttf) format('truetype');
}

body {
  font-family: Nunito, Helvetica, sans-serif;
}

.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.sidebar {
  width: 400px;
  margin: 30px 20px 30px 20px;
}

a { 
  color: inherit; 
} 

.img-sidebar {
  position: relative;
}

.sidebar-pic {
  width: 100%;
}

.sidebar-name {
  font-family: FjallaOne, Helvetica, sans-serif;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 50px;
  color: white;
}

.sidebar-titles {
  position: absolute;
  color: white;
  font-size: 20px;
}

.links-1 {
  top: 25px;
  right: 18px;
}

.links-2 {
  top: 45px;
  right: 18px;
}

.links-3 {
  top: 60px;
  right: 18px;
}

.ind-link {
  text-decoration: none;
}

.sidebar-titles .ind-link:hover {
  color: #738959;
}

.icons {
  margin-right: 5px;
  float: right;
  color: #738959;
}