.content {
    margin-left: 30px;
    margin-right: 30px;
    width: 700px;
}

.headline {
    font-family: FjallaOne, Helvetica, sans-serif;
    margin-bottom: 15px;
    font-size: 40px;
    color: #738959;
}


.project-block {
    width: 210px;
    height: 210px;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: #22291a;
    border: none;
    display: inline-flex;
}

.proj-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s ease;
}

.overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    display: flex;
    width: 210px;
    height: 210px;
    align-items: center;
    justify-content: center;
}

.project-block:hover .proj-img {
    opacity: 0.3;
}
  
.project-block:hover .overlay {
    opacity: 1;
}
  
.proj-text {
    color: white;
    font-family: FjallaOne, Helvetica, sans-serif;
    font-size: 20px;
}

a {
    text-decoration: none;
}