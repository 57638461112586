.content {
    margin-left: 30px;
    margin-right: 30px;
    width: 700px;
}

.headline {
    font-family: FjallaOne, Helvetica, sans-serif;
    margin-bottom: 15px;
    font-size: 70px;
    color: #738959;
}

.sub-headline {
    font-size: 40px;
    margin-top: 0px;
    margin-bottom: 40px;
    color: #b1c887;
}

.about-body {
    max-width: 575px;
    font-size: 20px;
}

.bolded {
    font-weight: bold;
    color: #738959;
}

.prior {
    font-weight: bold;
    color: #b1c887;
}

.abt-link:hover {
    color: #b1c887;
}

.learn-more-btn {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 45px;
    width: 120px;
    color: #b1c887;
    font-size: 15px;
    font-weight: bold;
    border: solid 1px #b1c887;
    background-color: white;
    border-radius: 8px;
}

.learn-more-btn:hover {
    color: #738959;
    border: solid 1px #738959;
}